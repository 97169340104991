import {CREATE, createActionName, DELETE, FETCH_LIST, FETCH_SINGLE, UPDATE} from '../../core/store/actions/commonActionTypes';
import {takeLatest} from 'redux-saga/effects';
import makeSagaRequest from './makeSagaRequest';
import apiService from '../../core/services/API';
import {IServerError} from '../../core/interfaces/common';

type RecursivePartial<T> = {
    [P in keyof T]?: RecursivePartial<T[P]>;
};

interface IRequestParams {
    apiCall: boolean;
    successCallback?;
    failureCallback?;
}

interface IListConfig {
    fetch: IRequestParams;
    create: IRequestParams;
    update: IRequestParams;
    delete: IRequestParams;
}

interface IListAllConfig {
    fetch: IRequestParams;
}

const DEFAULT_REQUEST_PARAMS: IRequestParams = {
    apiCall: true,
    successCallback: undefined,
    failureCallback: undefined
};

const DEFAULT_LIST_CONFIG: IListConfig = {
    fetch: DEFAULT_REQUEST_PARAMS,
    create: DEFAULT_REQUEST_PARAMS,
    update: DEFAULT_REQUEST_PARAMS,
    delete: DEFAULT_REQUEST_PARAMS
};

const DEFAULT_LIST_ALL_CONFIG: IListAllConfig = {
    fetch: DEFAULT_REQUEST_PARAMS
};

export class WatchersBuilder {
    public static* build(identifier: string, action: string, apiRequest,
        successCallback?: (payload: any) => void,
        failureCallback?: (error: IServerError) => void
        ) {
        const actionName = createActionName(identifier, action);
        yield takeLatest(actionName, makeSagaRequest({
            apiRequest,
            successCallback,
            failureCallback
        }));
    }

    public static buildList(identifier: string,
                            endpoint: string,
                            configExtension: Partial<{
                                fetch: Partial<IRequestParams>,
                                create: Partial<IRequestParams>,
                                update: Partial<IRequestParams>,
                                delete: Partial<IRequestParams>
                            }> = DEFAULT_LIST_CONFIG): any[] {
        const config = {
            fetch: {
                ...DEFAULT_LIST_CONFIG.fetch,
                ...configExtension.fetch
            },
            create: {
                ...DEFAULT_LIST_CONFIG.create,
                ...configExtension.create
            },
            update: {
                ...DEFAULT_LIST_CONFIG.update,
                ...configExtension.update
            },
            delete: {
                ...DEFAULT_LIST_CONFIG.delete,
                ...configExtension.delete
            }
        };
        const ret: any[] = [];
        if (config.fetch.apiCall) {
            const apiCall = typeof config.fetch.apiCall === 'function'
                ? config.fetch.apiCall
                : (params) => {
                    return apiService.get({
                        url: endpoint,
                        params
                    });
                };
            ret.push(this.build(identifier, FETCH_LIST, apiCall, config.fetch.successCallback));
        }
        if (config.create.apiCall) {
            const apiCall = typeof config.create.apiCall === 'function'
                ? config.create.apiCall
                : (data) => {
                    return apiService.post({
                        url: endpoint,
                        data
                    });
                };
            ret.push(this.build(identifier, CREATE, apiCall, config.create.successCallback));
        }
        if (config.update.apiCall) {
            ret.push(...this.buildUpdate(identifier, endpoint, config.update));
        }
        if (config.delete.apiCall) {
            const apiCall = typeof config.delete.apiCall === 'function'
                ? config.delete.apiCall
                : (id) => {
                    return apiService.delete({
                        url: `${endpoint}/${id}`
                    });
                };
            ret.push(this.build(identifier, DELETE, apiCall, config.delete.successCallback));
        }
        return ret;
    }

    public static buildListGet(identifier: string,
                            action: string,
                            endpoint: string,
                            configExtension: Partial<{
                                fetch: Partial<IRequestParams>
                            }> = DEFAULT_LIST_ALL_CONFIG): any[] {
        const config = {
          fetch: {
              ...DEFAULT_LIST_ALL_CONFIG.fetch,
              ...configExtension.fetch
          },
        };
        const ret: any[] = [];
        if (config.fetch.apiCall) {
            const apiCall = typeof config.fetch.apiCall === 'function'
                ? config.fetch.apiCall
                : (params) => {
                    return apiService.get({
                        url: endpoint,
                        params
                    });
                };
            ret.push(this.build(identifier, action, apiCall, config.fetch.successCallback));
        }
        return ret;
    }

    public static buildGetSingle(identifier, endpoint, configExtension = DEFAULT_REQUEST_PARAMS): any[] {
        const config = {
            ...DEFAULT_REQUEST_PARAMS,
            ...configExtension
        };
        if (!config.apiCall) {
            return [];
        }
        const apiCall = typeof config.apiCall === 'function'
            ? config.apiCall
            : (id) => {
                return apiService.get(`${endpoint}/${id}`);
            };
        return [this.build(identifier, FETCH_SINGLE, apiCall, config.successCallback)];
    }

    public static buildUpdate(identifier: string, endpoint: string, configExtension = DEFAULT_REQUEST_PARAMS): any[] {
        const config = {
            ...DEFAULT_REQUEST_PARAMS,
            ...configExtension
        };
        if (!config.apiCall) {
            return [];
        }
        const apiCall = typeof config.apiCall === 'function'
            ? config.apiCall
            : ({id, ...rest}) => {
                return apiService.patch({
                    url: `${endpoint}/${id}`,
                    data: rest
                });
            };
        return [this.build(identifier, UPDATE, apiCall, config.successCallback, config.failureCallback)];
    }
}
