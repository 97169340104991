import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
// Stores
import auth from './Auth';
import modal from './Modal';
import admins from '../../../pages/Admins/store/reducer';
import industries from '../../../pages/Industries/store/reducer';
import sectors from '../../../pages/Sectors/store/reducer';
import company from '../../../pages/CompanyDetails/store/reducer';
import companies from '../../../pages/ManageCompanies/store/reducer';
import downloads from '../../../pages/Downloads/store/reducer';
import reportedCompanies from '../../../pages/ReportedCompanies/store/reducer';
import reportedConversations from '../../../pages/ReportedConversations/store/reducer';
import changePassword from '../../../pages/ChangePassword/store/reducer';
import partnerCompanies from '../../../pages/PartnerCompanies/store/reducer';
import emailsGenerator from '../../../pages/EmailsGenerator/store/reducer';
import products from '../../../pages/Products/store/reducer';
import awards from '../../../pages/ManageAwards/store/reducer';

const reducer = combineReducers({
    router: routerReducer,
    auth,
    modal,
    admins,
    industries,
    sectors,
    products,
    ...company,
    ...companies,
    ...downloads,
    ...reportedCompanies,
    ...reportedConversations,
    ...changePassword,
    ...partnerCompanies,
    ...emailsGenerator,
    ...awards
});

export default reducer;
